<template>
  <div class="v-identify-document">
    <v-row dense>
      <v-col cols="4">
        <v-select
          v-model="valueType"
          data-test="select-document-type"
          :style="styleSelect"
          :outlined="outlined"
          :rounded="rounded"
          :filled="filled"
          :dense="dense"
          :auto="auto"
          :items="documentTypes"
          :label="$t('patient.tipo_documento')"
          item-text="name"
          item-value="id"
          @change="handleValidation"
        />
      </v-col>
      <v-col cols="8">
        <v-form ref="id">
          <v-text-field
            v-model="valueDocument"
            data-test="input-document"
            :outlined="outlined"
            :rounded="rounded"
            :filled="filled"
            :dense="dense"
            type="text"
            :rules="useRequired ? rules.requiredAndValidId : rules.validId"
            :label="`${$t('patient.document_id')}${useRequired ? '*' : ''}`"
            @input="handleValidation"
          />
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FormValidation from '@/mixins/FormValidation';
import { setSelectorsDocumentType } from '@/utils';
export default {
  name: 'VIdentifyDocument',

  mixins: [FormValidation],

  props: {
    outlined: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    auto: {
      type: Boolean,
      default: true,
    },
    styleSelect: {
      type: String,
      default: 'width: 99%',
    },
    styleLayout: {
      type: String,
      default: 'margin: 0px -4px 0px 0px;',
    },
    idDocumentType: {
      type: Number,
      default: 1,
    },
    idDocument: {
      type: String,
      default: '',
    },
    useRequired: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      valueType: 1,
      valueDocument: this.idDocument,
    };
  },

  computed: {
    documentTypes() {
      let documentTypes = [
        { id: 1, name: 'DNI' },
        { id: 2, name: 'NIE' },
        { id: 3, name: this.$t('patient.pasaporte') },
        { id: 4, name: this.$t('common.otro') },
      ];

      documentTypes = setSelectorsDocumentType(documentTypes);

      return documentTypes;
    },
  },

  watch: {
    idDocumentType() {
      this.valueType = this.idDocumentType;
    },
  },

  mounted() {
    this.setDocumentType();
    this.handleValidation();
  },

  created() {
    this.valueType = this.idDocumentType;
  },

  methods: {
    handleValidation() {
      const isValid = this.$refs.id.validate();
      this.$emit('valid', isValid);
      this.handleEmit();
    },

    handleEmit() {
      this.$emit('input', {
        idDocumentType: this.valueType,
        idDocument: this.valueDocument.toUpperCase(),
      });
    },

    setDocumentType() {
      if (this.documentTypes.length === 1) {
        this.valueType = this.documentTypes[0]['id'];
      }
    },
  },
};
</script>
